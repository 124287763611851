import React from 'react'

import logo from '../img/logo.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer>
        <img
          src={logo}
          alt="logo"
        />
      </footer>
    )
  }
}

export default Footer
