import React from 'react'
import { Link } from 'gatsby'

const Navbar = (props) => {

  const active = props.page;

  return (
    <nav
      className="navbar"
      role="navigation"
      aria-label="main-navigation"
    >
      <Link 
        className="nav-item" 
        id={active === 'home'?'active-item':''} 
        to="/"
      >
        home 
      </Link>
      <Link 
        className="nav-item" 
        id={active === 'projects'?'active-item':''} 
        to="/projects"
      >
        projects 
      </Link>
      {/* <Link 
        className="nav-item" 
        id={active === 'blog'?'active-item':''} 
        to="/blog"
      >
        blog 
      </Link> */}
      <Link 
        className="nav-item" 
        id={active === 'story'?'active-item':''} 
        to="/story"
      >
        story 
      </Link>
      <Link 
        className="nav-item" 
        id={active === 'contact'?'active-item':''} 
        to="/contact"
      >
        contact 
      </Link>

    </nav>
  )
  
}

export default Navbar
