import React from 'react'

import Layout from '../../components/Layout'
import Navbar from '../../components/Navbar'
import BlogRoll from '../../components/BlogRoll'
import Footer from '../../components/Footer'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Navbar page='blog' />
        <div className="blog content">
          <h1 className="page-header">
            ~ My Writeups ~
          </h1>

          <div className="container">
            <BlogRoll />
          </div>
        </div>
        
        <Footer />
      </Layout>
    )
  }
}
